import React, { useRef } from "react";
import styled from "styled-components";

const SkillsContainer = styled.div`
  margin: 1rem auto;
  box-shadow: 0px 0px 4px #c3c3c3;
  border-radius: 8px;
  text-align: center;
  width: 25%;
  min-width: 300px;
  background-color: #f0f5fd;
  padding: 1rem 1.5rem;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
    clip-path: ellipse(100% 80% at 5% 0%);
    border-radius: inherit;
  }

  h3 {
    font-size: 2em;
    color: #545454;
    display: inline-block;
  }

  p {
    margin-top: 1rem;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const SkillsList = styled.ul`
  font-size: 1.3em;
  font-weight: 800;
  margin-top: 1rem;
  li {
    padding: 0.5rem;
  }
`;

const SkillsBox = ({ title, skills, children }) => {
  const skillsRef = useRef(null);

  return (
    <SkillsContainer ref={skillsRef}>
      <div style={{ position: "relative", zIndex: "2" }}>
        <h3>{title}</h3>
        {children}
        <SkillsList>
          {skills.map((skill) => (
            <li key={skill._id}>{skill.title}</li>
          ))}
        </SkillsList>
      </div>
    </SkillsContainer>
  );
};

export default SkillsBox;
