import React, { Fragment } from "react";
import TopSection from "./top-section";
import Container from "../layout/container";
import AboutMe from "../home/about/AboutMe";
import Projects from "./featured-projects/Projects";
import Skills from "./skills/SkillsSection";
import Contact from "./contact/Contact";

const HomePage = () => {
  return (
    <Fragment>
      <TopSection />
      <Container>
        <AboutMe />
        <Skills />
        <Projects />
        <Contact />
      </Container>
    </Fragment>
  );
};

export default HomePage;
