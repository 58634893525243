import React, { useState, useEffect, Fragment } from "react";
import sanityClient from "../../../client";
import PageSection from "../../layout/PageSection";
import FeaturedProjects from "./FeaturedProjects";
import OtherProjects from "./OtherProjects";

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const response = await sanityClient.fetch`
        *[_type == 'project']{
          _id,
          featured,
          title,
          description,
          excerpt,
          ghURL,
          liveURL,
          mainImage{
            asset->{
              _id,
              url
            },
            altText,
            crop
          } 
        }
      `;
      setProjects(response);
    };
    fetchProjects();
  }, []);

  return (
    <Fragment>
      <PageSection id="featured-projects">
        <h2>Featured Projects</h2>
        <FeaturedProjects
          projects={projects.filter((project) => project.featured)}
        />
      </PageSection>
      <PageSection>
        <OtherProjects
          projects={projects.filter((project) => !project.featured)}
        />
      </PageSection>
    </Fragment>
  );
};

export default Projects;
