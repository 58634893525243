import React, { useRef } from "react";
import useVisibility from "../../../helpers/useVisibilty";
import sanityClient from "../../../client.js";
import styled from "styled-components";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ImageWrapper = styled.div`
  position: relative;
  max-width: 370px;
  box-shadow: 8px 8px 16px #4c4c4c;
  margin: 2rem auto;

  &:hover .project-links {
    opacity: 1;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    background: linear-gradient(to top, #000, #0000 60%);
    z-index: 1;
    opacity: ${(props) => (props.isVisible ? "1" : "0")};
    transition: opacity 0.5s;
  }
`;

const ProjectImg = styled.img`
  width: 100%;
  display: block;
`;

const ProjectLinks = styled.div`
  color: #fff;
  font-size: 1.8em;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem;
  background-color: #476ce4;
  box-shadow: -4px 4px 12px #272727;
  opacity: 0;
  transition: opacity 0.4s;
  z-index: 1000;

  > * + * {
    margin-left: 1rem;
  }
`;

const ProjectText = styled.div`
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: opacity 1s;
  position: absolute;
  bottom: 1rem;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  text-align: center;
  line-height: 1.7;
  padding: 0 1.5rem;
  z-index: 2;
`;

const ProjectTitle = styled.h3`
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const ProjectCard = ({ project }) => {
  const projectRef = useRef(null);
  const isVisible = useVisibility({ ref: projectRef, threshold: 1 });

  return (
    <ImageWrapper ref={projectRef} isVisible={isVisible}>
      <ProjectLinks className="project-links">
        <a href={project.ghURL} target="_blank" rel="noopener noreferrer">
          <i
            aria-label="Link to Noughts and Crosses Github repo"
            className="fab fa-github icon"
          ></i>
        </a>
        <a href={project.liveURL} target="_blank" rel="noopener noreferrer">
          <i
            aria-label="Link to Noughts and Crosses"
            className="fas fa-link icon"
          ></i>
        </a>
      </ProjectLinks>
      <ProjectImg src={urlFor(project.mainImage).size(500, 500).url()} />
      <ProjectText isVisible={isVisible}>
        <ProjectTitle>{project.title}</ProjectTitle>
        <span>{project.excerpt}</span>
      </ProjectText>
    </ImageWrapper>
  );
};

export default ProjectCard;
