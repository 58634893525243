import React, { useEffect, useState } from "react";
import styled from "styled-components";
import computer from "../../../assets/images/laptop.png";
import bluescreen from "../../../assets/images/bluescreen.png";

const Wrapper = styled.div`
  position: relative;
  width: 450px;
  margin: 0 auto;

  @media (max-width: 800px) {
    display: none;
  }
`;

const Mask = styled.div`
  position: absolute;
  top: 7%;
  left: 9.5%;
  width: 79%;
  height: 70%;
`;

const Cursor = styled.div.attrs((props) => ({
  style: {
    top: props.mousePos.y + "%",
    left: props.mousePos.x + "%",
  },
}))`
  position: absolute;
  z-index: 5;
  color: #4f4f4f;
`;

const BlueScreen = styled.div`
  position: absolute;
  top: 7%;
  left: 9.5%;
  width: 81%;
  height: 77%;
  z-index: 1000;
  background-image: url(${bluescreen});
`;

const ComputerImg = () => {
  const [mousePos, setMousePos] = useState({ y: 0, x: 0 });
  const [blueScreen, setBlueScreen] = useState(false);

  const easterEggPos =
    mousePos.x < 20 && mousePos.x > 17 && mousePos.y > 37 && mousePos.y < 44;

  useEffect(() => {
    const handleBlueScreen = () => {
      if (easterEggPos) {
        setBlueScreen(true);
      }
    };
    window.addEventListener("click", handleBlueScreen);

    return () => window.removeEventListener("click", handleBlueScreen);
  });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const x = (e.clientX / window.innerWidth) * 100;
      const y = (e.clientY / window.innerHeight) * 100;
      setMousePos({ x, y });
    };
    window.addEventListener("mousemove", handleMouseMove);

    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <Wrapper aria-hidden="true">
      <img
        style={{ width: "100%", height: "auto" }}
        alt="laptop"
        src={computer}
      />
      <Mask>
        <Cursor
          className={
            easterEggPos ? "fas fa-hand-pointer" : "fas fa-mouse-pointer"
          }
          mousePos={mousePos}
        />
      </Mask>
      {blueScreen && <BlueScreen />}
    </Wrapper>
  );
};

export default ComputerImg;
