import React from "react";
import ProjectPreview from "./ProjectPreview";

const FeaturedProjects = ({ projects }) => {
  return projects.map((project, i) => (
    <ProjectPreview key={project._id} project={project} order={i} />
  ));
};

export default FeaturedProjects;
