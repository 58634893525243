import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  height: 100px;
  background-color: #15112c;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <p>Copyright Paul Firmin 2021</p>
      <p>
        Illustration by <a href="undefined">Icons 8</a> from{" "}
        <a href="https://icons8.com/">Icons8</a>
      </p>
    </StyledFooter>
  );
};

export default Footer;
