import { useState, useEffect, useRef } from "react";

const useVisibility = ({ ref, threshold }) => {
  const [isVisible, setIsVisible] = useState(false);
  const observer = useRef(null);

  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
      }
    });
  };

  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(handleIntersect, {
        root: null,
        threshold: threshold,
      });
    }
    if (ref.current) observer.current.observe(ref.current);

    return () => observer.current.disconnect();
  }, [ref, threshold]);

  return isVisible;
};

export default useVisibility;
