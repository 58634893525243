import React from "react";
import FloatingBG from "./FloatingBG.js";
import styled from "styled-components";
import TopHero from "./TopHero.js";

const TopWrapper = styled.div`
  background: linear-gradient(#15112c, #2010ad);
  perspective: 4000px;
  overflow: hidden;
  width: 100%;
  position: relative;
  min-height: 500px;
  height: 100vh;
  clip-path: ellipse(170% 100% at 0 0);

  @media (max-width: 900px) {
    height: 100vh;
  }
`;

const TopSection = () => {
  return (
    <TopWrapper>
      <FloatingBG />
      <TopHero />
    </TopWrapper>
  );
};

export default TopSection;
