import React from "react";
import styled from "styled-components";
import Content from "../../layout/Content";
import ProjectCard from "./ProjectCard";

const Wrapper = styled(Content)`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const OtherProjects = ({ projects }) => {
  return (
    <Content>
      <h2>Fun and Games!</h2>
      <p style={{ textAlign: "center", margin: "0 auto", maxWidth: "800px" }}>
        I have adapted a number of classic games for the web and the Linux
        command line. A few of my favourites are featured here.
      </p>
      <Wrapper>
        {projects.map((project) => (
          <ProjectCard key={project._id} project={project} />
        ))}
      </Wrapper>
    </Content>
  );
};

export default OtherProjects;
