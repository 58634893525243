import styled from "styled-components";

export default styled.div`
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  padding: 0 2rem;
  overflow: hidden;

  h2 {
    font-size: 3.0em;
    color: rgb(51, 44, 141);
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  @media (max-width: 900px) {
    width: 100%;
    padding: 0 1rem;
    font-size: 0.8rem;
    margin-top: 2rem;

    h2 {
      text-align: center;
    }
`;
