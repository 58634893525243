import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sanityClient from "../../../client";
import PageSection from "../../layout/PageSection";
import Content from "../../layout/Content.js";
import SkillsBox from "./SkillsBox";
import ComputerImg from "./ComputerImg";

const SkillsRow = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
`;

const SkillIcon = styled.i`
  display: block;
  margin-top: 1rem;
  font-size: 2rem;
  color: ${(props) => props.color};
`;

const SkillsSection = () => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const fetchSkills = async () => {
      const response = await sanityClient.fetch`
        *[_type == 'skills']{
          title,
          _id,
          category,
        }
      `;
      setSkills(response);
    };
    fetchSkills();
  }, []);

  return (
    <PageSection id="skills">
      <h2>Skills and Tech</h2>
      <Content>
        <ComputerImg />
        <SkillsRow>
          <SkillsBox
            title="Front End"
            skills={skills.filter((skill) => skill.category === "FRONTEND")}
          >
            <SkillIcon color="#f49494" className="fas fa-palette" />
            <p>
              I specialise in creating responsive, accessible and intuitive
              front end experiences. My weapons of choice are React and Styled
              Components.
            </p>
          </SkillsBox>
          <SkillsBox
            title="Back End"
            skills={skills.filter((skill) => skill.category === "BACKEND")}
          >
            <SkillIcon color="#4386ce" className="fas fa-globe" />
            <p>
              Serverside, I have experience creating RESTful APIs with
              Express.js and MongoDB, but also possess basic knowledge of SQL
              and Ruby on Rails.
            </p>
          </SkillsBox>
          <SkillsBox
            title="Tools"
            skills={skills.filter((skill) => skill.category === "OTHER")}
          >
            <SkillIcon color="#77b7ab" className="fas fa-wrench" />
            <p>
              Testing, bundling , linting and version control are all essential
              parts of a productive workflow, and where would any developer be
              without the right toolbox?
            </p>
          </SkillsBox>
        </SkillsRow>
      </Content>
    </PageSection>
  );
};

export default SkillsSection;
