import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import background from "../../../assets/images/background.png";
import midground from "../../../assets/images/midground.png";
import foreground from "../../../assets/images/foreground.png";

const FloatingBackground = styled.figure`
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;width: 100%;
  height: 100%;
  transition: 0.6s;
  transition-timing-function: ease-out;

  div {
    width: 100%;
    height: 100%;
    background-size: 100%;
  }
  div:first-child {
    background: url(${background});
    transform: translateZ(50px);
  }
  div:nth-child(2) {
    background: url(${midground});
    position: absolute;
    left: 0;
    top: 0;
    transform: translateZ(250px);
  }
  div:nth-child(3) {
    background: url(${foreground});
    position: absolute;
    left: 0;
    top: 0;
    transform: translateZ(450px);
`;

const FloatingBG = () => {
  const bgRef = useRef();

  useEffect(() => {
    const bg = bgRef.current;
    const bgMove = (e) => {
      const y = (e.y - bg.clientHeight / 2) / 100;
      const x = -(e.x - bg.clientWidth / 2) / 100;

      bg.style.transform = `rotateY(${x}deg) rotateX(${y}deg)`;
    };
    bg.addEventListener("mousemove", bgMove);

    return () => bg.removeEventListener("mousemove", bgMove);
  }, [bgRef]);

  return (
    <FloatingBackground ref={bgRef}>
      <div />
      <div />
      <div />
    </FloatingBackground>
  );
};

export default FloatingBG;
