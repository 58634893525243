import React from "react";
import styled from "styled-components";
import Content from "../../layout/Content";

const ContactGroup = styled.div`
  text-align: center;
  margin-bottom: 1rem;

  h3 {
    font-size: 2rem;
  }
  p {
    font-size: 1.4rem;
    margin: 0;
  }
`;

const Contact = () => {
  return (
    <Content>
      <h2>Get in touch</h2>
      <ContactGroup>
        <h3>
          <strong>Email</strong>
        </h3>
        <p>
          <a href="mailto:hello@paulfirmin.co.uk">hello@paulfirmin.co.uk</a>
        </p>
      </ContactGroup>
      <ContactGroup>
        <h3>
          <strong>GitHub</strong>
        </h3>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://github.com/pafirmin"
          >
            pafirmin
          </a>
        </p>
      </ContactGroup>
    </Content>
  );
};

export default Contact;
