import React from "react";
import styled from "styled-components";

const HeroWrapper = styled.div`
  color: #fff;
  z-index: 2;
  margin: 12% auto 0 15%;
  position: relative;
  line-height: 2.5rem;
  font-weight: 700;
  display: inline-block;
  max-width: 750px;
  padding: 1rem;

  @media (max-width: 900px) {
    width: 100%;
    margin: 10rem auto;
    font-size: 0.7rem;
  }
`;

const HeroText = styled.div`
  h1 {
    font-size: 6em;
  }
  p {
    margin-top: 3.3rem;
    font-size: 2.2em;
    line-height: 1.5;
    text-shadow: 1px 1px 25px $dark-purple;
  }
`;

const TopHero = () => {
  return (
    <HeroWrapper>
      <HeroText>
        <h1>Hello.</h1>
        <p>
          My name is Paul Firmin. I am a full stack web developer based in
          London, UK.
        </p>
      </HeroText>
    </HeroWrapper>
  );
};

export default TopHero;
