import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
   padding: 0;
    margin: 0;
    box-sizing: border-box;
  { 
  body {
    font-family: 'Ubuntu', sans-serif;
    background-color: #f4f4f4;
  }
  a {
    color: inherit;
  }
  ul {
    list-style: none;
  }
`;
