import React, { useRef } from "react";
import useVisibility from "../../../helpers/useVisibilty";
import sanityClient from "../../../client";
import styled from "styled-components";
import Content from "../../layout/Content.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ProjectWrapper = styled(Content)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 4px 4px 15px #c3c3c3;
  border-radius: 6px;
  margin: 3rem 0;
  padding: 3rem;
  background: #fff;

  @media (max-width: 1200px) {
    font-size: 0.8rem;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 1.8rem;
  }
`;

const ProjectHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #c3c3c3;
  position: relative;

  h3 {
    font-size: 2em;
  }
  a {
    font-size: 1.1em;
  }
  a:nth-child(2) {
    margin-left: 1rem;
  }

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const PreviewImage = styled.figure`
  width: 55%;
  position: relative;
  order: 0;
  display: flex;
  justify-content: ${(props) => (props.isOffset ? "flex-end" : "flex-start")};
  alilgn-items: flex-end;
  padding-top: 5%;

  img {
    width: 90%;
    height: auto;
    display: block;
    z-index: 2;
  }

  &:after {
    content: "";
    position: absolute;
    width: ${(props) => (props.isVisible ? "80%" : "0")};
    height: ${(props) => (props.isVisible ? "80%" : "0")};
    transition: 1s ease-out;
    opacity: ${(props) => (props.isVisible ? "1" : "0")};
    top: ${(props) => (props.isVisible ? "0" : "50px")};
    left: ${(props) =>
      props.isOffset ? (props.isVisible ? "0px" : "50px") : ""};
    right: ${(props) => (props.isOffset ? "" : props.isVisible ? "0" : "50px")};
    background-color: #b3d7e8;
    z-index: 0;
  }
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const PreviewDescription = styled.div`
  width: 40%;
  order: ${(props) => (props.isOffset ? "2" : "-1")};

  @media (max-width: 900px) {
    width: 100%;
    order: 2;
  }
`;

const ProjectPreview = ({ project, order }) => {
  const projectRef = useRef(null);
  const isVisible = useVisibility({ ref: projectRef, threshold: 0.3 });

  return (
    <ProjectWrapper>
      <PreviewDescription isOffset={order % 2 === 0}>
        <ProjectHeader isVisible={isVisible}>
          <h3>{project.title}</h3>
          <div>
            <a target="_blank" rel="noopener noreferrer" href={project.ghURL}>
              GitHub
            </a>{" "}
            <a target="_blank" rel="noopener noreferrer" href={project.liveURL}>
              See it live
            </a>
          </div>
        </ProjectHeader>
        <BlockContent blocks={project.description} />
      </PreviewDescription>
      <PreviewImage
        ref={projectRef}
        isVisible={isVisible}
        isOffset={order % 2 === 0}
      >
        <img
          src={urlFor(project.mainImage).url()}
          alt={project.mainImage.altText}
        />
      </PreviewImage>
    </ProjectWrapper>
  );
};

export default ProjectPreview;
