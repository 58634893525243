import React from "react";
import Content from "../../layout/Content";
import PageSection from "../../layout/PageSection.js";
import styled from "styled-components";

const AboutContent = styled(Content)`
  display: flex;
  align-items: center;
  max-width: 900px;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ProfileIcon = styled.i`
  display: block;
  text-align: center;
  font-size: 3rem;
  color: #4386ce;
  margin-bottom: 1rem;
`;

const AboutMe = () => {
  return (
    <PageSection id="about">
      <h2>About Me</h2>
      <ProfileIcon className="far fa-user-circle" />
      <AboutContent>
        <div>
          <p>
            I am an aspiring web developer from London with a passion for
            creating beautiful, intuitive and responsive webpages that adhere to
            the best practices of modern design web-design and accessibility.
          </p>
          <p>
            I have a keen eye for the finer details and an intuition for design
            that guides my approach to all my projects. I love to solve problems
            and am always open to a new challenge. A fast and determined
            learner, I am constantly seeing to expand my knowledge and refine my
            skills.
          </p>
        </div>
        {/* <Portrait alt="Paul Firmin" src={portrait} /> */}
      </AboutContent>
    </PageSection>
  );
};

export default AboutMe;
