import React, { Fragment } from "react";
import GlobalStyle from "./GlobalStyle.js";
import HomePage from "./components/home/HomePage";
import Footer from "./components/footer/Footer";

const App = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <HomePage />
      <Footer />
    </Fragment>
  );
};

export default App;
