import styled from "styled-components";

export default styled.div`
  color: #595959;
  p {
    line-height: 1.6;
    font-size: 1.1em;
    margin-top: 1.5rem;
  }
  @media (max-width: 1200px) {
    margin: 0;
    font-size: 1em;
  }
`;
